import React, { useCallback } from 'react';
import styled from 'styled-components';

interface IToggleProps {
  getValue: (val: boolean) => void;
  value?: boolean;
}
function ToggleSwitch({ value, getValue }: IToggleProps) {
  const handleToggle = useCallback(() => {
    getValue(!value);
  }, [value, getValue]);

  return (
    <SwitchContainer>
      <SwitchInput onChange={handleToggle} checked={value} />
    </SwitchContainer>
  );
}

export default ToggleSwitch;
export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #c3c8db;
  border-radius: 12px;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: #a4ff00; /* Indicator color when turned on */
  }

  &:before {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white; /* Default indicator color when turned off */
    top: 1px;
    left: 1px;
    transition: 0.3s;
  }

  &:checked:before {
    transform: translateX(26px);
    background-color: #c3c8db; /* Indicator color when turned on */
  }
`;
