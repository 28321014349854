import React, { useEffect, useMemo, useRef, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './carousel.scss';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/arrow-right.svg';
import { TApprovalStatus, capitalizeFirstChar, checkLargeScreen, reportingListMessagesList } from 'utils';
import BgImageWithChildAndIcon from 'components/bgImageWithChildAndIcon';
import styled from 'styled-components';
import PinchZoomRotate from 'components/zoomImage';
import { BagTypeIcon } from 'components/icons';

const CarouselSlider = ({
  data,
  onPress,
  fullScreenWidth = false,
  selectedSlideIndex,
  isDeliveryItems,
  setCurrentReportingItemReason,
  isImageZoomAble = false,
  isOuterSlider = false,
  isHideFacilyErrorStatus,
}: CarouselSliderProps) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(selectedSlideIndex || 0);
  const swiperContainerRef = useRef<Swiper | any>(null);
  const sliderSpeed = 500;
  const isSingleImage = data?.length < 2;
  const [resetZoomRotate, setResetZoomRotate] = useState(false);

  useEffect(() => {
    setResetZoomRotate(!resetZoomRotate);
  }, [activeSlideIndex]);
  useEffect(() => {
    const params = {
      slidesPerView: fullScreenWidth ? 1 : 2,
      speed: sliderSpeed,
      centeredSlides: true,
      lazy: true,
      spaceBetween: 18,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChange: (e: { activeIndex: number }) => {
          setActiveSlideIndex(e.activeIndex);
        },
      },
      breakpoints: {
        0: {
          //  windowWidth >=0px
          spaceBetween: 18,
        },
      },
    };
    const swiper = new Swiper(swiperContainerRef.current, params);
    return () => {
      swiper?.destroy(true, true);
    };
  }, []);

  useEffect(() => {
    if (selectedSlideIndex) {
      swiperContainerRef.current.swiper.slideTo(selectedSlideIndex);
    }
  }, [selectedSlideIndex]);

  const renderPaginations = useMemo(() => {
    if (isSingleImage) return null;
    return (
      <div className="justify-content-center d-flex">
        {data?.map((_: any, index: number) => {
          const onPaginationHandler = () => {
            if (swiperContainerRef.current.swiper) {
              swiperContainerRef.current.swiper?.slideTo(index);
            }
          };
          const isActiveIndex = activeSlideIndex === index;
          return <div key={index} className={isActiveIndex ? 'pagination-dots-active' : 'pagination-dots'} onClick={onPaginationHandler}></div>;
        })}
      </div>
    );
  }, [data, activeSlideIndex]);
  const Items = ({ fullScreenWidth, url, reason, index, item }: ItemDetailsProps) => {
    const viewImageHandler = () => {
      const currentIndex = swiperContainerRef.current.swiper.activeIndex;
      if (currentIndex !== index) {
        // If the clicked image is not the active one, move to that image
        swiperContainerRef.current.swiper.slideTo(index);
        return;
      }
      onPress?.(swiperContainerRef.current.swiper.activeIndex);
    };

    const renderMessageImage = (url: string) => {
      const messages = data && data?.length > 0 ? reportingListMessagesList(data, url) : [];

      return (
        <>
          {messages.map((message, index) => {
            return (
              <div key={index}>
                {message.error && (
                  <BannerError>
                    <BannerTextError>{capitalizeFirstChar(message.message)}</BannerTextError>
                  </BannerError>
                )}
                {!message.error && (
                  <BannerSuccess>
                    <BannerTextSuccess>{capitalizeFirstChar(message.message)}</BannerTextSuccess>
                  </BannerSuccess>
                )}
              </div>
            );
          })}
        </>
      );
    };

    const renderIconsForDelivery = (
      <BagTypeIcon iconSize="1rem" bagType={item?.approvalStatus && WF_STATUSES.includes(item.approvalStatus) ? 'WF' : item?.bagType} />
    );

    return (
      <div key={index} className={`${isOuterSlider ? 'outer-slider swiper-slide' : 'swiper-slide'}`}>
        <div onClick={viewImageHandler} className="w-100 h-100">
          <OuterContainer isZoomAble={isImageZoomAble}>
            <InnerContainer fullScreenWidth={fullScreenWidth} activeSlide={activeSlideIndex === index} isZoomAble={isImageZoomAble}>
              {renderMessageImage && !isHideFacilyErrorStatus && <TextContainerTop>{renderMessageImage(url)}</TextContainerTop>}
              {isDeliveryItems ? (
                <BgImageWithChildAndIcon
                  cardWidth={'100%'}
                  cardHeight={'100%'}
                  key={item?.id}
                  url={url}
                  text={reason}
                  handleImageClick={() => viewImageHandler()}
                  icon={
                    <BagTypeIcon iconSize="1rem" bagType={item?.approvalStatus && WF_STATUSES.includes(item.approvalStatus) ? 'WF' : item?.bagType} />
                  }
                  isGalleryIconShow={true}
                  reportDetailsList={item.reportDetailsList}
                />
              ) : (
                <>{isImageZoomAble ? <PinchZoomRotate src={url} reset={resetZoomRotate} /> : <ImageStyle src={url} alt={'image'} />}</>
              )}
              {!isDeliveryItems && <div className="image-shadow-text">{capitalizeFirstChar(reason)}</div>}
              {isDeliveryItems ? renderIconsForDelivery : renderBagImage}
            </InnerContainer>
          </OuterContainer>
        </div>
      </div>
    );
  };

  const WF_STATUSES: TApprovalStatus[] = ['PENDING_WF_TO_CP', 'PROCEED_WF'];
  const renderBagImage = useMemo(
    () =>
      !fullScreenWidth ? null : (
        <div className="bag-image">
          <BagTypeIcon bagType={data[0]?.approvalStatus && WF_STATUSES.includes(data[0].approvalStatus) ? 'WF' : data[0]?.bagType} />
        </div>
      ),
    [data]
  );

  const renderSwiperWrapper = useMemo(() => {
    const onSwipeRight = () => {
      swiperContainerRef.current.swiper.slideTo(swiperContainerRef.current.swiper.activeIndex + 1);
    };
    const onSwipeLeft = () => {
      swiperContainerRef.current.swiper.slideTo(swiperContainerRef.current.swiper.activeIndex - 1);
    };
    const renderSwipeRight = checkLargeScreen() && fullScreenWidth && !isSingleImage && (
      <div onClick={onSwipeRight} className="swiper-button-next">
        <ArrowRight />
      </div>
    );
    const renderSwipeLeft = checkLargeScreen() && fullScreenWidth && !isSingleImage && (
      <div onClick={onSwipeLeft} className="swiper-button-prev ">
        <ArrowLeft />
      </div>
    );
    return (
      <>
        <div className={`swiper-wrapper ${data.length === 1 ? 'pb-0' : ''} `}>
          {data?.map(({ reason, url, imageUrl, item, side, location, part, area, type }: ItemDetailsProps, index: number) => {
            const reasonText = `
            ${reason.replace('stained', '').replace('damaged', '').toLocaleLowerCase()}
            ${part ? part.toLowerCase() : ''}
            ${location ? location.toLowerCase() : ''} 
            ${area ? area.toLowerCase() : ''}
            ${side ? ' - ' + side.toLowerCase() + ' side' : ''} 
            `.replace(/_/g, ' ');
            const reasonTextAllCapitalizedWords = reasonText.replace(/\b\w/g, (l) => l.toUpperCase()).replace('At', 'at');

            return Items({
              fullScreenWidth: fullScreenWidth,
              url: imageUrl || url,
              reason: reasonTextAllCapitalizedWords,
              index: index,
              item: item,
            });
          })}
        </div>
        {/* device which has greater than 600px screen width */}
        {renderSwipeLeft}
        {renderSwipeRight}
      </>
    );
  }, [fullScreenWidth, isSingleImage, activeSlideIndex]);

  useEffect(() => {
    setCurrentReportingItemReason?.(
      `${
        data[activeSlideIndex].type
          ? data[activeSlideIndex].type
              .replace(/_/g, ' ')
              .replace('Original', '')
              .replace(/OTHER/g, 'Unspecified ' + `${data[activeSlideIndex].reason === 'damaged' ? 'damage' : 'stain'}`)
              .toLocaleLowerCase()
          : data[activeSlideIndex].reason !== 'Original'
          ? data[activeSlideIndex].reason === 'damaged'
            ? 'Damaged'
            : 'Hard stain'
          : ''
      }`
    );
  }, [activeSlideIndex]);

  return (
    <>
      <div className="swiper" ref={swiperContainerRef}>
        {renderSwiperWrapper}
        {renderPaginations}
      </div>
    </>
  );
};

export default CarouselSlider;

const OuterContainer = styled.div<{
  isZoomAble?: boolean;
}>`
  flex: 1;
  height: ${({ isZoomAble }) => (isZoomAble ? '100%' : '224px')};
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  display: flex;
  border-radius: 6px;
`;

const InnerContainer = styled.div<{
  fullScreenWidth: boolean | undefined;
  activeSlide: boolean;
  isZoomAble?: boolean;
}>`
  display: flex;
  height: ${({ fullScreenWidth, activeSlide }) => (fullScreenWidth ? '100%' : activeSlide ? '224px' : '139px')};
  width: ${({ fullScreenWidth }) => (fullScreenWidth ? '' : '172px')};
  border-radius: 6px !important;
  position: relative;
  overflow: hidden;
  transition: height 0.4s;
  background: ${({ isZoomAble }) => (isZoomAble ? 'transparent' : '#D9D9D9')};
`;

const ImageStyle = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 6px !important;
`;

export const TextContainerTop = styled.div`
  position: fixed;
  color: white;
  margin: 8px;
  height: 30%;
  width: max-content;
`;

export const BannerSuccess = styled.div`
  background: #d8fff8;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  position: relative;
`;

export const BannerError = styled.div`
  background: #fbeef2;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  position: relative;
`;

export const BannerTextSuccess = styled.div`
  color: #2ea692;
  padding: 0px 6px;
  text-align: center;
  font: 400 10px 'Inter-Regular', sans-serif;
  position: relative;
`;

export const BannerTextError = styled.div`
  color: #c83c3e;
  padding: 0px 6px;
  text-align: center;
  font: 400 10px 'Inter-Regular', sans-serif;
  position: relative;
`;
