import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './styles.scss';
interface IToolTipPros {
  children: JSX.Element;
  name: string;
}

const ItemWithTooltip: React.FC<IToolTipPros> = ({ children, name }) => {
  const text = <Tooltip id="tooltip">{name}</Tooltip>;
  return (
    <OverlayTrigger placement="bottom" overlay={text}>
      {children}
    </OverlayTrigger>
  );
};

export default ItemWithTooltip;
