import React, { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as CrossIcon } from 'assets/images/icons/crossIcon.svg';
import { Activity, capitalizeFirstChar, ColorList, COLORS, ServiceLine } from 'utils';
import { CarouselSlider, ItemWithTooltip } from 'components';
import { IImageProps } from 'modules/itemsOverview/components/approvalCards/types';
import './styles.scss';
import TimeLog from 'components/itemTimeLog/itemTimeLog';
import { ReactComponent as StainAndDamageInfoIcon } from 'assets/images/icons/info.svg';
import { ReactComponent as ItemCleanedIcon } from 'assets/images/icons/tick-green.svg';
import { ReactComponent as Cross } from 'assets/images/icons/cross.svg';
import PinchZoomRotate from 'components/zoomImage';

interface ItemDetailsProps {
  show: boolean;
  onHide: () => void;
  data: IImageProps[];
  itemCode?: string;
  itemColors?: ColorList[];
  brand?: string;
  itemType?: string;
  selectedSlideIndex: number;
  singleImageUrl?: string;
  activities?: Activity[];
  headerTitle?: string;
  isShowImagesOnly?: boolean;
  isImageZoomAble?: boolean;
  serviceType?: string;
}
const ItemDetailsDialog: React.FC<ItemDetailsProps> = ({
  show,
  onHide,
  data,
  itemCode,
  brand,
  itemType,
  itemColors,
  selectedSlideIndex,
  singleImageUrl,
  activities,
  headerTitle,
  isShowImagesOnly = false,
  isImageZoomAble = false,
  serviceType,
}) => {
  const isHideFacilyErrorStatus = [ServiceLine.SHOE_SERVICE, ServiceLine.FINERY].includes(serviceType as ServiceLine);
  const [currentReportingItemReason, setCurrentReportingItemReason] = React.useState<any>(null);
  const renderReasons = useMemo(() => {
    const damagedCount = data?.filter((item) => item.reason === 'damaged').length || undefined;
    // const stainedCount = data?.filter((item) => item.reason === 'stained').length || undefined;
    const allStainTypesTextArray = data
      ?.filter((item) => item.reason)
      .map(
        (item) =>
          item.reason &&
          item.reason
            .replace(/_STAIN/g, '')
            .replace(/Original/g, '')
            .replace(/_/g, ' ')
            .toLocaleLowerCase()
      );
    const removeDuplicatesStainTypes = allStainTypesTextArray.filter((item, index) => allStainTypesTextArray.indexOf(item) === index && item !== '');
    const allReasonText = removeDuplicatesStainTypes.length > 0 ? removeDuplicatesStainTypes?.join(' and ') : '';
    return removeDuplicatesStainTypes.length === 0 && !damagedCount ? null : (
      <>
        <StainAndDamageInfoIcon />
        <div className="reason-text-container">
          <div className="reason-text">
            {currentReportingItemReason ? (
              <p className="ml-1"> {capitalizeFirstChar(currentReportingItemReason)}</p>
            ) : (
              <>
                <p className="ml-1">{capitalizeFirstChar(allReasonText)}</p>
              </>
            )}
          </div>
        </div>
      </>
    );
  }, [data, currentReportingItemReason]);

  const renderIsCleaned = useMemo(() => {
    const isCleanedStatus = data?.some((item: any) => item.isActioned && !item.isCantBeCleaned && item.isRemoved && !item.isNotCleaned);

    return isCleanedStatus || data?.length === 1 ? (
      <>
        <ItemCleanedIcon />
        <div className="reason-text-container">
          <div className="reason-text-cleaned ml-1">Item cleaned</div>
        </div>
      </>
    ) : (
      <>
        <Cross />
        <div className="reason-text-container">
          <div className="reason-text ml-1">Item uncleaned</div>
        </div>
      </>
    );
  }, [data]);
  if (show)
    return (
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={`custom-modal ${isImageZoomAble ? 'image-zoomable-modal' : ''}`}
      >
        <Modal.Body className={`${isImageZoomAble ? 'p-0' : ''}`}>
          {!isImageZoomAble && (
            <>
              {headerTitle ? (
                <div className="text-center overflow-hidden pb-4">
                  <div className="d-flex align-items-center justify-content-center p-0 pt-2">
                    <div className="cross-icon-container"></div>
                    {!isImageZoomAble && (
                      <>
                        <h2 className="heading">{headerTitle}</h2>
                        <div onClick={onHide} className={`cross-icon-container icon`}>
                          <CrossIcon onClick={onHide} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-center overflow-hidden">
                  <div className="d-flex align-items-center justify-content-center p-0 pt-2">
                    {(brand || itemType) && <h2 className="heading">{brand + ' - ' + itemType}</h2>}
                    <div onClick={onHide} className="cross-icon-container">
                      <CrossIcon />
                    </div>
                  </div>
                  {itemColors && (
                    <div className="d-flex pb-3 justify-content-center align-items-center">
                      <div className="colors-container">
                        {itemColors?.map(({ name, index }: ColorList) => (
                          <div
                            key={index}
                            style={{
                              background: COLORS[name?.toLowerCase()]?.hex,
                              border: name?.toLowerCase() === 'white' ? '1px solid #E3E1E1' : 'none',
                            }}
                            className="colors-items"
                          ></div>
                        ))}
                      </div>
                      <ItemWithTooltip children={<div className="item-code-text">({itemCode})</div>} name={'Item ID'} />
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <div className={`${isImageZoomAble ? '' : 'pb-3'} overflow-hidden`}>
            {isImageZoomAble ? (
              <PinchZoomRotate src={data[selectedSlideIndex].url!} reset={true} reason={data[selectedSlideIndex].reason} close={() => onHide()} />
            ) : (
              <CarouselSlider
                fullScreenWidth
                data={data}
                selectedSlideIndex={selectedSlideIndex}
                setCurrentReportingItemReason={setCurrentReportingItemReason}
                isImageZoomAble={isImageZoomAble}
                isHideFacilyErrorStatus={isHideFacilyErrorStatus}
              />
            )}
          </div>
          {!isShowImagesOnly && (
            <>
              {!isHideFacilyErrorStatus && renderReasons && (
                <div className="d-flex mb-2 pl-1  ml-0 justify-content-center align-items-center">{renderReasons}</div>
              )}
              {!isHideFacilyErrorStatus && renderIsCleaned && (
                <div className="d-flex mb-2 pl-1  ml-0 justify-content-center align-items-center">{renderIsCleaned}</div>
              )}
            </>
          )}
        </Modal.Body>
        {activities && <TimeLog activities={activities} />}
      </Modal>
    );
  return null;
};

export default ItemDetailsDialog;
