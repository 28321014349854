import React, { useState, useRef } from 'react';
import { ReactComponent as Search } from 'assets/images/icons/search.svg';
import { ReactComponent as KeyboardArrow } from 'assets/images/icons/keyboard-arrow.svg';
import { ReactComponent as KeyboardCross } from 'assets/images/icons/keyboard-cross.svg';
import styled from 'styled-components';

const FloatingSearch: React.FC<IFloatingSearchProps> = ({ value, setSearchValue, placeholder }) => {
  const [inputText, setInputText] = useState(value || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
    setSearchValue(e.target.value);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocusClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <ContainerBg>
        <div>
          <InputWrapper isFocused={isFocused}>
            <div className="d-flex justify-content-center align-items-center">
              <Search />
            </div>
            <CustomInput
              ref={inputRef}
              value={inputText}
              type="text"
              placeholder={placeholder || 'Search'}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {inputText !== '' && (
              <KeyboardCrossContainer
                onClick={() => {
                  setSearchValue('');
                  setInputText('');
                  handleFocusClick();
                }}
                isFocused={isFocused}
              >
                <KeyboardCross />
              </KeyboardCrossContainer>
            )}
          </InputWrapper>
          {isFocused && (
            <ButtonWrapper
              onClick={() => {
                setSearchValue('');
                setInputText('');
              }}
            >
              <KeyboardArrow />
            </ButtonWrapper>
          )}
        </div>
      </ContainerBg>
    </>
  );
};

const ContainerBg = styled.div`
  position: fixed;
  background: #e1defb;
  // top: 90vh;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 375px;
  height: 78px;
  z-index: 1;
  &:focus {
    outline: none; /* Remove default focus outline if not needed */
    border: 1px solid #291482;
  }
`;

const InputWrapper = styled.div<{ isFocused: boolean }>`
  position: fixed;
  background: #ffffff;
  border-radius: 100px;
  border-style: solid;
  border-color: ${({ isFocused }) => (isFocused ? '#291482' : '#efeff4')};
  border-width: 1px;
  padding: ${(props) => (props.isFocused ? '8px 52px 8px 8px' : '8px 60px 8px 8px')};
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: ${({ isFocused }) => (isFocused ? '294px' : '329px')};
  height: 34px;
  top: 22px;
  left: 23px;

  &:focus-within {
    border-color: #251b7d;
  }
`;

const CustomInput = styled.input`
  color: #291482 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.1px !important;
  background: #ffffff;
  border-style: solid;
  font: 300 12px/18px 'Inter-Light', sans-serif;
  font-size: 12px;
  font-weight: bolder;
  border-color: #ffffff;
  width: 100%;
  width: 262px;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #585871;
    opacity: 0.6;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 238px;
    font: 300 12px/18px 'Inter-Light', sans-serif;
    font-size: 12px;
  }
  :-ms-input-placeholder {
    color: #585871;
    opacity: 0.6;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 238px;
    font: 300 12px/18px 'Inter-Light', sans-serif;
    font-size: 12px;
  }

  &:focus {
    outline: none;
  }
`;
const KeyboardCrossContainer = styled.div<{ isFocused: boolean }>`
  line-height: 16px;
  margin-right: ${({ isFocused }) => (isFocused ? '-44px' : '-62px')};
  display:flex;
  justify-content:center;
  align-items-center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: 32px;
  margin-left: 288px;
  height: 34px;
  top: 22px;
  left: 37px;
`;

// const ArrowUpsideDown = styled.div`
//   transform: rotate(180deg);
// `;

export default FloatingSearch;
