import React, { useRef, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { capitalizeFirstChar } from 'utils';

const PinchZoomRotate: React.FC<{ src: string; reset: boolean; reason?: string; close?: () => void }> = ({
  src,
  reset,
  reason,
  close = () => {},
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [transform, setTransform] = useState<string>('scale(1) rotate(0rad)');
  const [initialDistance, setInitialDistance] = useState<number | null>(null);
  const [initialScale, setInitialScale] = useState<number | null>(null);
  const [initialRotation, setInitialRotation] = useState<number | null>(null);

  const handlePinchZoomRotate = useCallback(
    (event: TouchEvent) => {
      const container = containerRef.current;

      if (!container) {
        return;
      }

      const touches = event.touches;
      if (touches.length === 2) {
        event.preventDefault();

        const [touch1, touch2] = touches;

        const distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY);
        const angle = Math.atan2(touch2.clientY - touch1.clientY, touch2.clientX - touch1.clientX);

        if (initialDistance === null) {
          setInitialDistance(distance);
          setInitialScale(1);
          setInitialRotation(angle);
        }

        const scaleChange = distance / initialDistance! / initialScale!;
        const rotationValue = angle - initialRotation!;

        const currentScale = parseFloat(transform.match(/scale\(([^)]+)\)/)?.[1] || '1');
        const zoomThreshold = 5;

        if (currentScale * scaleChange > zoomThreshold) {
          return;
        }

        setTransform(`scale(${currentScale * scaleChange}) rotate(${rotationValue}rad)`);
      }
    },
    [initialDistance, initialScale, initialRotation]
  );

  const handleZoomRotateEnd = useCallback(() => {
    setInitialDistance(null);
    setInitialScale(null);
    setInitialRotation(null);

    // If the current scale is less than the original scale, set it back to the original scale
    const currentScale = parseFloat(transform.match(/scale\(([^)]+)\)/)?.[1] || '1');
    const originalScale = 1;

    if (currentScale < originalScale) {
      setTransform(`scale(${originalScale}) rotate(0rad)`);
    }
  }, [transform]);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    container.addEventListener('touchmove', handlePinchZoomRotate, { passive: false });
    container.addEventListener('touchend', handleZoomRotateEnd);

    return () => {
      container.removeEventListener('touchmove', handlePinchZoomRotate);
      container.removeEventListener('touchend', handleZoomRotateEnd);
    };
  }, [handlePinchZoomRotate, handleZoomRotateEnd]);

  useEffect(() => {
    // Reset transform when the reset prop changes
    if (reset) {
      setTransform('scale(1) rotate(0rad)');
    }
  }, [reset]);
  return (
    <PinchZoomRotateContainer ref={containerRef} onClick={() => close && close()}>
      <div style={{ transform }} className="mt-5 mx-4">
        <PinchZoomRotateImage src={src} alt="Pinch Zoom Rotate Image" />
        {reason && (
          <ReasonTextContainer>
            <ReasonText className="image-shadow-text">{capitalizeFirstChar(reason)}</ReasonText>
          </ReasonTextContainer>
        )}
      </div>
    </PinchZoomRotateContainer>
  );
};

const PinchZoomRotateContainer = styled.div`
  position: relative;
  overflow: hidden;
  touch-action: pinch-zoom rotate;
  transform-origin: center center;
  transition: transform 0.3s ease-out;
  height: 100vh;
  @media (min-width: 768px) {
    width: 375px;
    height: 50vh;
    object-fit: contain; /* Maintain original size until zoomed in */
    border-radius: 6px !important;
    left: 32%;
  }
`;

const PinchZoomRotateImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain original size until zoomed in */
  border-radius: 8px !important;
`;

const ReasonText = styled.div`
  position: absolute;
  padding-bottom: 24px;
  width: 100%;
  text-align: center;
  color: #fafbfc;
  font-family: 'Inter Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.4px;
  border-radius: 8px !important;
`;

const ReasonTextContainer = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 4.59%, rgba(23, 23, 23, 0) 100%);
`;

export default PinchZoomRotate;
