import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  containerFill: string;
  ribbonFill: string;
  text: string;
  pathDimentions?: string;
}

const RibbonLabel: React.FC<Props> = ({ containerFill, ribbonFill, text, pathDimentions = '0h110a5', ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d={`M5 ${pathDimentions} 5 0 0 1 5 5v14a5 5 0 0 1-5 5H0V5a5 5 0 0 1 5-5z`} fill={containerFill} />
        <text fontFamily="Ubuntu" fontSize="12" fill="#FFF">
          <tspan x="8" y="17">
            {text}
          </tspan>
        </text>
        <path fill={ribbonFill} d="M4.806 24v9.862L0 24z" />
      </g>
    </svg>
  );
};
export default RibbonLabel;
