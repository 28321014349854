import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'modules';
import { IntlProvider } from 'react-intl';
import { washmenTheme } from 'theme/washmen/washmen';

//i18n
import En from 'i18n/en.json';
import { ThemeProvider } from 'styled-components';

const App: React.FC = () => {
  return (
    <IntlProvider locale="en" messages={En}>
      <ThemeProvider theme={washmenTheme}>
        <Router>
          <Routes />
        </Router>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
