import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

//assets
import WashmenLogo from 'assets/images/new-logo.png';

const Header: React.FC = () => (
  <Container className="text-light bg-white mb-2 p-3" fluid>
    <Row>
      <Col sm="auto">
        <Image src={WashmenLogo} />
      </Col>
    </Row>
  </Container>
);

export default Header;
