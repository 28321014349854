import { DefaultTheme } from 'styled-components';
import { washmenColors } from './washmenColors';
import colors from 'theme/colors/color';

export const washmenTheme: DefaultTheme = {
  name: 'washmen',
  colors: washmenColors,
  typography: {
    fontFamily: {},
  },
  components: {
    invoice: {
      card: {
        border: '1px',
        borderRadius: '8px',
        borderColor: colors.lavender,
      },
      seprator: {
        borderColor: colors.lavender,
      },
      heading: {
        color: washmenColors.primary,
        fontSize: '26px',
        fontWeight: '700',
        lineHeight: '30px',
      },
      totalPaid: {
        color: colors.white,
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '21px',
        letterSpacing: '0.4px',
        fontStyle: 'normal',
        background: washmenColors.linearGradient,
        borderLeft: '8px',
        borderRight: '8px',
      },
    },
  },
};
