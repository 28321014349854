import React from 'react';
import Image from 'react-bootstrap/Image';

//assets

import CPBag from 'assets/images/icons/cp-new.svg';
import PBag from 'assets/images/icons/pb-new.svg';
import WFBag from 'assets/images/icons/wf-new.svg';
import HCBag from 'assets/images/icons/hc-new.svg';
import SCBag from 'assets/images/icons/ic-sc-bag.svg';
import TFBag from 'assets/images/icons/tf-bag.svg';
import { BagTypes } from 'utils';

interface Props {
  bagType: BagTypes;
  iconSize?: string;
}

const ImageMapper: { [key in BagTypes]: string } = {
  CP: CPBag,
  P: PBag,
  WF: WFBag,
  HC: HCBag,
  SC: SCBag,
  shoes: SCBag,
  finery: TFBag,
};

const BagTypeIcon: React.FC<Props> = ({ bagType, iconSize = '1.65rem' }) => <Image src={ImageMapper[bagType]} fluid style={{ width: iconSize }} />;

export default BagTypeIcon;
