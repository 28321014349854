import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BannerPlaceHolder } from 'components';
import { Order } from 'utils/interfaces';
import OrderDetailsDialog from '../modules/itemsOverview/components/orderDetailsDialog';

// icons
import { ReactComponent as DetailsIcon } from 'assets/images/icons/ic-orders.svg';

interface Props {
  order?: Order | undefined;
  orderId?: string | undefined;
  loaderHeight?: string;
  textFontSize?: string;
  textFontWeight?: number;
  iconWidth?: string;
}

const OrderDetails: React.FC<Props> = ({ order, orderId, loaderHeight, textFontSize = '14px', iconWidth = '2rem', textFontWeight = 400 }) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="w-100 bg-white">
        <Container bsPrefix="container-sm" className="bg-white py-3">
          {!(order || orderId) ? (
            <BannerPlaceHolder height={loaderHeight} />
          ) : (
            <Row className="font-medium align-items-center px-3" noGutters>
              <Col className="font-weight-normal" xs="auto">
                <DetailsIcon className="mr-3" style={{ width: iconWidth, height: 'auto' }} />
              </Col>
              <Col style={{ fontSize: textFontSize, fontWeight: textFontWeight }}>
                Order No. {order ? order.orderAlphaId : orderId}
                {order && (
                  <>
                    <br />
                    <button className="btn btn-link p-0 text-primary" onClick={() => setModalShow(true)}>
                      View order details
                    </button>
                  </>
                )}
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {order && <OrderDetailsDialog onHide={() => setModalShow(false)} show={modalShow} order={order} />}
    </>
  );
};
export default OrderDetails;
