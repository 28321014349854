import React, { useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/images/icons/according-arrow-down.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/according-arrow-up.svg';
import './styles.scss';
const Accordion: React.FC<IAccordionProps> = ({ title, startIcon, children, count }) => {
  const [open, setOpen] = useState(false);

  const renderRightComponent = useMemo(() => {
    return (
      <div className="accordion-right-component">
        {/* <h3 className="mr-5"> {count}</h3> */}
        {open ? <MinusIcon /> : <PlusIcon />}
      </div>
    );
  }, [MinusIcon, PlusIcon, open]);
  const renderTitle = useMemo(() => {
    return <h3 className="item-text"> {title || 'Title'}</h3>;
  }, [title]);
  const renderLeft = useMemo(() => {
    return <div className="accordion-left-component"> {startIcon}</div>;
  }, [startIcon]);
  const handleAccordion = () => {
    children && setOpen(!open);
  };
  return (
    <div className="item-container-parent">
      <div onClick={handleAccordion} className="item-container">
        {renderLeft}
        {renderTitle}
        <div className="item-count">{count}</div> {renderRightComponent}
      </div>
      {open && children}
    </div>
  );
};

export default Accordion;
