import { IImageProps } from 'modules/itemsOverview/components/approvalCards/types';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { DetailsList, InvoiceItems, IOrderItem } from 'utils/interfaces';
/**
 * @description extract an error message from the info Code
 * @param infoCode
 * @returns first infoCode error found
 */
export const InfoCodeParser = (infoCode: any): string => {
  let errorMsg = '';
  if (!infoCode) {
    return 'unknown.error';
  }
  for (let key in infoCode) {
    if (infoCode.hasOwnProperty(key)) {
      errorMsg = infoCode[key].error || infoCode[key].message || 'unknown.error';
      break;
      // do something with each element here
    }
  }
  return errorMsg;
};

export const getCodeFromError = (error: any) => {
  if (!error.response || !error.response.data || !error.response.data.infoCodes) {
    return undefined;
  }

  if (error.response.data.infoCodes) {
    return Object.keys(error.response.data.infoCodes)[0];
  }

  return undefined;
};

export const getReportTypeFromReport = (reportList: DetailsList[]) => {
  const hasStain = reportList.filter((i) => i.reason === 'STAINED').length > 0;
  const hasDamage = reportList.filter((i) => i.reason === 'DAMAGED').length > 0;

  if (hasStain && hasDamage) return 'stain/damage';
  else if (hasStain && !hasDamage) return 'stain';
  else if (!hasStain && hasDamage) return 'damage';
  else return 'none';
};

export const getMessage = ({ approvalStatus, approvalOrigin, reportDetailsList }: IOrderItem) => {
  switch (approvalStatus) {
    case 'REJECTED': {
      if ((approvalOrigin === 'FACILITY' || approvalOrigin === 'SYSTEM') && getReportTypeFromReport(reportDetailsList) !== 'none')
        return 'rejectedByFacility';
      if (approvalOrigin === 'CUSTOMER') return 'rejectedByCustomer';
      return 'rejectedUnknownReason';
    }
    case 'APPROVED': {
      if (approvalOrigin === 'FACILITY' || approvalOrigin === 'SYSTEM') return 'approvedByFacility';
      if (approvalOrigin === 'CUSTOMER') return 'approvedByCustomer';
      return '';
    }

    case 'PROCEED_CP_PENDING':
    case 'PENDING': {
      return 'pendingApproval';
    }
    default:
      return '';
  }
};

export const sortItemByPriority = (reportDetailsList: IImageProps[], priority: Record<string, number>) => {
  if (!Array.isArray(reportDetailsList) || !reportDetailsList.every((item) => 'reason' in item)) {
    return null;
  }
  if (typeof priority !== 'object' || priority === null) {
    return null;
  }

  return reportDetailsList.sort((a, b) => {
    const getPriority = (report: IImageProps): number => priority[report?.reason?.toUpperCase()] || 0;
    const priority1 = getPriority(a);
    const priority2 = getPriority(b);

    return priority1 - priority2;
  });
};

export const checkLargeScreen = () => {
  const width = window.innerWidth;
  const screenSizes = [{ width: 600 }];

  const isLargeScreen = screenSizes.some((size) => {
    return width >= size.width;
  });

  return isLargeScreen;
};

export const getPluralSuffix = (num: number) => {
  if (num && num > 1) return 's';
  return '';
};
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const reportingListMessagesList = (reportDetailsList: DetailsList[], imageUrl?: string) => {
  let messages: {
    error: boolean;
    message: string;
  }[] = [];

  const removeAllWithReasonOriginalAndOther = imageUrl
    ? reportDetailsList.filter((item: any) => item.reason !== 'Original' && item.issueId && item.imageUrl === imageUrl)
    : reportDetailsList.filter((item: any) => item.reason !== 'Original' && item.issueId);

  const isAnyIsActioned = removeAllWithReasonOriginalAndOther.some((report) => report.isActioned);

  if (isAnyIsActioned) {
    handleActionedReports(messages, removeAllWithReasonOriginalAndOther);
  } else {
    handleNonActionedReports(messages, removeAllWithReasonOriginalAndOther, imageUrl);
  }

  return messages;
};

const handleActionedReports = (messages: any[], reports: any[]) => {
  if (reports.length === 1) {
    handleSingleReport(messages, reports[0]);
  } else if (reports.length > 1) {
    handleMultipleReports(messages, reports);
  }
};

const handleSingleReport = (messages: any[], report: any) => {
  const isRemoved = report.isRemoved;
  const isActioned = report.isActioned;

  messages.push({
    error: !(isRemoved && isActioned),
    message: isRemoved && isActioned ? 'Stain removed' : 'Stain not removed',
  });
};

const handleMultipleReports = (messages: any[], reports: any[]) => {
  const filterAllStains = reports.filter((report) => report.reason === 'STAINED');
  const filterAllDamages = reports.filter((report) => report.reason === 'DAMAGED');

  handleStains(messages, filterAllStains);
  handleDamages(messages, filterAllDamages);
};

const handleStains = (messages: any[], stains: any[]) => {
  if (stains.length > 0) {
    const allStainsRemoved = stains.every((report) => report.isRemoved && report.isActioned);
    const allStainsNotRemoved = stains.every((report) => !report.isRemoved && report.isActioned);
    const isAllReasonsAreStains = stains.every((report) => report.reason === 'STAINED');

    if (allStainsRemoved) {
      messages.push({ error: false, message: 'Stain removed' });
    } else if (allStainsNotRemoved) {
      messages.push({ error: true, message: 'Stain not removed' });
    } else if (isAllReasonsAreStains) {
      messages.push({ error: false, message: 'Stains partially removed' });
    }
  }
};

const handleDamages = (messages: any[], damages: any[]) => {
  if (damages.length > 0) {
    const allDamagesFixed = damages.every((report) => report.isRemoved);

    messages.push({
      error: !allDamagesFixed,
      message: allDamagesFixed ? 'Damage fixed' : 'Damage not fixed',
    });
  }
};

const handleNonActionedReports = (messages: any[], reports: any[], imageUrl?: string) => {
  const reason = reports[0];

  if (reason && imageUrl) {
    messages.push({
      error: true,
      message: reason.reason.toLocaleUpperCase() === 'DAMAGED' ? 'Damage not fixed' : 'Stain not removed',
    });
  } else if (!imageUrl) {
    const filterAllStains = reports.filter((report) => report.reason === 'STAINED');
    const filterAllDamages = reports.filter((report) => report.reason === 'DAMAGED');

    handleNonActionedStains(messages, filterAllStains);
    handleNonActionedDamages(messages, filterAllDamages);
  }
};

const handleNonActionedStains = (messages: any[], stains: any[]) => {
  if (stains.length > 0) {
    const isAllReasonsAreStains = stains.every((report) => report.reason === 'STAINED');

    if (isAllReasonsAreStains) {
      messages.push({ error: true, message: 'Stain not removed' });
    }
  }
};

const handleNonActionedDamages = (messages: any[], damages: any[]) => {
  if (damages.length > 0) {
    messages.push({ error: true, message: 'Damage not fixed' });
  }
};

export const capitalizeFirstChar = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export function convertIsoDateFormat(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function numericDateFormat(timestamp: number) {
  if (!timestamp) {
    return '';
  }
  return moment(timestamp).format('MMMM DD, YYYY');
}

// Sort function based on service bagType priority
export function sortByServiceType(lines: InvoiceItems[]) {
  const serviceTypePriority: { [key: string]: number } = {
    CP: 1,
    P: 2,
    HC: 3,
    WF: 4,
  };
  return lines?.sort((a, b) => {
    const priorityA = serviceTypePriority[a?.serviceType];
    const priorityB = serviceTypePriority[b?.serviceType];
    return priorityA - priorityB;
  });
}
