import React, { useMemo } from 'react';
import { ImageProps } from './types';
import {
  BackgroundImage,
  BgImageWithChildContainer,
  Text,
  TextContainer,
  TextContainerTop,
  BannerSuccess,
  BannerTextSuccess,
  BannerError,
  BannerTextError,
} from './styled';
import { ReactComponent as GalleryImage } from 'assets/images/icons/image-gallery.svg';
import './bgImageWithChildAndIcon.scss';
import { ServiceLine, reportingListMessagesList } from 'utils';

const BgImageWithChildAndIcon: React.FC<ImageProps> = ({
  text,
  url,
  handleImageClick,
  icon,
  isGalleryIconShow,
  reportDetailsList,
  cardWidth,
  cardHeight,
  serviceType,
}) => {
  const renderRight = useMemo(() => {
    return <> {icon}</>;
  }, [icon]);
  const messages = reportDetailsList && reportDetailsList?.length > 0 ? reportingListMessagesList(reportDetailsList) : [];
  const isHideFacilyErrorStatus = [ServiceLine.SHOE_SERVICE, ServiceLine.FINERY].includes(serviceType as ServiceLine);
  return (
    <BgImageWithChildContainer width={cardWidth} height={cardHeight} onClick={handleImageClick}>
      {!isHideFacilyErrorStatus && (
        <>
          {messages.map((message, index) => {
            return (
              <div key={index}>
                {message.error && (
                  <TextContainerTop index={index}>
                    <BannerError>
                      <BannerTextError>{message.message}</BannerTextError>
                    </BannerError>
                  </TextContainerTop>
                )}
                {!message.error && (
                  <TextContainerTop index={index}>
                    <BannerSuccess>
                      <BannerTextSuccess>{message.message}</BannerTextSuccess>
                    </BannerSuccess>
                  </TextContainerTop>
                )}
              </div>
            );
          })}
        </>
      )}
      <BackgroundImage src={url} alt="img" />
      <TextContainer>
        {isGalleryIconShow && <GalleryImage />}
        <Text>
          <span className="image-text-and-icon">{text || 'text'}</span>
        </Text>
        <Text>
          <span className="w-100 d-flex justify-content-end">{renderRight}</span>
        </Text>
      </TextContainer>
    </BgImageWithChildContainer>
  );
};

export default BgImageWithChildAndIcon;
