import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.scss';

interface Props {
  amount?: number;
  showHeader?: boolean;
}

/**
 * @description content placeholder image like facebook
 * https://cloudcannon.com/deconstructions/2014/11/15/facebook-content-placeholder-deconstruction.html
 */
const ContentPlaceHolder: React.FC<Props> = ({ amount = 1, showHeader = false }) => {
  //create dynamic array of length {amount}
  const rows = Array.from({ length: amount }, (_, idx) => `${++idx}`);

  return (
    <>
      {rows.map((v, index) => (
        <Container bsPrefix="container-sm" className="mt-4" key={index}>
          <Row>
            <Col>
              <div className="timeline-wrapper">
                <div className="timeline-item">
                  <div className="animated-background">
                    <div className="background-masker header-top"></div>
                    <div className="background-masker header-left"></div>
                    <div className="background-masker header-right"></div>
                    <div className="background-masker header-bottom"></div>
                    <div className="background-masker subheader-left"></div>
                    <div className="background-masker subheader-right"></div>
                    <div className="background-masker subheader-bottom"></div>
                    <div className="background-masker content-top"></div>
                    <div className="background-masker content-first-end"></div>
                    <div className="background-masker content-second-line"></div>
                    <div className="background-masker content-second-end"></div>
                    <div className="background-masker content-third-line"></div>
                    <div className="background-masker content-third-end"></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      ))}
    </>
  );
};

export default ContentPlaceHolder;
