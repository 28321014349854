import React, { Suspense, useEffect } from 'react';
import { RouteComponentProps, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { Loader } from 'components';
import { API } from 'services/api';
import 'url-search-params-polyfill';

// import module styles
import './module.scss';
import { useQuery } from 'utils';

const ItemsOverview = React.lazy<any>(() => import('./itemsOverview/itemOverview'));
const ProofOfDelivery = React.lazy<any>(() => import('./proofOfDelivery/proofOfDeliveryRoute'));
const ProofOfCollection = React.lazy<any>(() => import('./proofOfCollection/proofOfCollectionRoute'));
const InvoicePage = React.lazy<any>(() => import('./InvoicePage/invoicePage'));
const FineryInvoice = React.lazy<any>(() => import('./fineryInvoice/fineryInvoicePage'));

interface Props extends RouteComponentProps {}

/**
 * Modules Entry Routes
 */
const Routes: React.FC<Props> = () => {
  let query = useQuery();
  // if token is present, set the auth header globally
  useEffect(() => {
    const token = query.get('token');
    if (token) {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      // redirect to washmen.com in case no token was found
      delete API.defaults.headers.common['Authorization'];
      window.location.href = 'https://washmen.com';
    }
  }, [query]);

  return (
      <Suspense fallback={<Loader show />}>
        <Switch>
          <Route exact path="/">
            <ItemsOverview />
          </Route>
          <Route exact path="/proofOfCollection">
            <ProofOfCollection />
          </Route>
          <Route exact path="/proofOfDelivery">
            <ProofOfDelivery />
          </Route>
          <Route exact path="/receipt">
            <InvoicePage />
          </Route>
          <Route exact path="/finery/receipt">
            <FineryInvoice />
          </Route>
          <Redirect to="/" />
        </Switch>
      </Suspense>
  );
};
export default withRouter(Routes);
