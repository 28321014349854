import styled from 'styled-components';

export const BgImageWithChildContainer = styled.div<{
  width?: string;
  height?: string;
}>`
  width: ${(props) => props?.width || '48.5%'};
  height: ${(props) => props?.height || '174px'};
  flex-shrink: 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 14.5px;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const Text = styled.p`
  bottom: 8px;
  margin-bottom: 0;
  left: 8px;
  right: 8px;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  display: flex;
  font-family: 'Inter Medium';
  justify-content: space-between;
`;
export const TextContainer = styled.div`
  position: absolute;
  padding-left: 8px;
  color: white;
  bottom: 0px;
  padding-bottom: 32px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 14.5%, rgba(23, 23, 23, 0) 100%);
`;

export const TextContainerTop = styled.div<{ index: number }>`
  position: absolute;
  color: white;
  top: ${(props) => (props.index >= 1 ? '28px' : '4px')};
  padding-left: 8px;
  padding-right: 8px;
  height: 30%;
`;

export const BannerSuccess = styled.div`
  background: #d8fff8;
  border-radius: 20px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  position: relative;
  margin-top: 4px;
`;

export const BannerError = styled.div`
  background: #fbeef2;
  border-radius: 20px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  position: relative;
  margin-top: 4px;
`;

export const BannerTextSuccess = styled.div`
  color: #2ea692;
  padding: 0px 6px;
  text-align: center;
  font: 400 10px 'Inter-Regular', sans-serif;
  position: relative;
`;

export const BannerTextError = styled.div`
  color: #c83c3e;
  padding: 0px 6px;
  text-align: center;
  font: 400 10px 'Inter-Regular', sans-serif;
  position: relative;
`;
