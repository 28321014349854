import React, { useMemo } from 'react';
import Image from 'react-bootstrap/Image';

//assets

import ApplePayIcon from 'assets/images/icons/apple-pay-card-Icon.svg';
import VisaCardIcon from 'assets/images/icons/visa-card-Icon.svg';
import MasterCardIcon from 'assets/images/icons/master-card-Icon.svg';
import CashIcon from 'assets/images/icons/cash-icon.svg';
import PaymentLinkIcon from 'assets/images/icons/payment-link-icon.svg';
import { PaymentMethod, TPaymentCardTypes } from 'utils';
import styled from 'styled-components';

interface IPaymentCardTypeProps {
  cardType: TPaymentCardTypes;
  iconSize?: string;
}


const PaymentCardTypeIcon: React.FC<IPaymentCardTypeProps> = ({ cardType, iconSize = '1.3rem' }) => {
const PaymentTypeIcon = useMemo(()=>{
  switch (cardType) {
    case PaymentMethod.CARD:
      return VisaCardIcon;
    case PaymentMethod.MASTER_CARD:
      return MasterCardIcon;
    case PaymentMethod.APPLE_PAY:
      return ApplePayIcon;
    case PaymentMethod.CASH:
      return CashIcon;
    case PaymentMethod.PAYMENT_LINK:
      return PaymentLinkIcon;
    default:
      return '';
  }
},[cardType])

return <StyledImage src={PaymentTypeIcon} fluid  />
}

export default PaymentCardTypeIcon;

const StyledImage = styled(Image)`
 margin-right:7.6px;
 margin-bottom: 3.2px;
`;
