import React from 'react';
import './itemTimeLog.scss';
import { Activity } from 'utils';
import moment from 'moment';

const TimeLogItem: React.FC<{ title: string; datetime: number | string; isLast: boolean }> = ({ title, datetime, isLast }) => (
  <div className="log-container d-flex">
    <div className="blue-circle-container d-block">
      <div className="blue-circle"></div>
      {!isLast && <div className="blue-line"></div>}
    </div>
    <div className="time-log-container">
      <h3 className="item-title-text mb-0">{title}</h3>
      <h5 className="item-date-text text-muted mb-0">{datetime ? datetime : <span className="text-white">.</span>}</h5>
    </div>
  </div>
);
const TimeLog: React.FC<{ activities?: Activity[] }> = ({ activities = [] }) => {
  return (
    <>
      {activities.length > 0 && (
        <>
          <h3 className="mt-14 item-heading-text time-log-text mb-0">Time log</h3>
          <div className="time-log">
            {activities.map((data, index) => (
              <TimeLogItem key={index} title={data.activity} datetime={data.createdAt} isLast={index === activities.length - 1} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TimeLog;
