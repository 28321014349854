import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.scss';

interface Props {
  amount?: number;
  height?: string;
}

/**
 * @description content placeholder image like facebook
 * https://cloudcannon.com/deconstructions/2014/11/15/facebook-content-placeholder-deconstruction.html
 */
const BannerPlaceHolder: React.FC<Props> = ({ amount = 1, height = '50px' }) => {
  //create dynamic array of length {amount}
  const rows = Array.from({ length: amount }, (_, idx) => `${++idx}`);

  return (
    <>
      {rows.map((v, index) => (
        <Row key={index}>
          <Col>
            <div className="timeline-wrapper">
              <div className="timeline-item" style={{ border: '0', padding: '0' }}>
                <div className="animated-background" style={{ height: height }}>
                  <div className="background-masker header-top"></div>
                  <div className="background-masker header-left"></div>
                  <div className="background-masker header-right"></div>
                  <div className="background-masker header-bottom"></div>
                  <div className="background-masker subheader-left"></div>
                  <div className="background-masker subheader-right"></div>
                  <div className="background-masker subheader-bottom"></div>
                  <div className="background-masker content-top" style={{ height: '10px' }}></div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ))}
    </>
  );
};

export default BannerPlaceHolder;
