import React from 'react';
import './styles.scss';
import styled from 'styled-components';
import SliderComponent from './slidersIcons';

const LoadingPlaceholderForCollection: React.FC = () => {
  return (
    <MainContainer>
      <ContentRow>
        <Column2Placeholder />
        <Div>
          <Div2 />
          <Div3 />
          <Div4 />
        </Div>
        <PostContentContainer>
          <PostMediaContainer>
            <MediaPlaceholder />
          </PostMediaContainer>
          <PostTextPlaceholder />
        </PostContentContainer>
        <div className="d-flex justify-content-center">
          <SliderComponent />
        </div>
        <StyledBagsRows >
          <div
            style={{
              width: 327,
              height: 35,
              left: 1,
              top: 207,
              position: 'absolute',
              background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
              borderRadius: 6,
            }}
          />
          <div style={{ width: 330, height: 32, left: 0, top: 164, position: 'absolute' }}>
            <div
              style={{
                width: 30,
                height: 30,
                left: 0,
                top: 1,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 15,
              }}
            />
            <div
              style={{
                width: 285,
                height: 32,
                left: 45,
                top: 0,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 6,
              }}
            />
          </div>
          <div style={{ width: 330, height: 32, left: 0, top: 83, position: 'absolute' }}>
            <div
              style={{
                width: 30,
                height: 30,
                left: 0,
                top: 1,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 15,
              }}
            />
            <div
              style={{
                width: 285,
                height: 32,
                left: 45,
                top: 0,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 6,
              }}
            />
          </div>
          <div style={{ width: 330, height: 32, left: 0, top: 124, position: 'absolute' }}>
            <div
              style={{
                width: 30,
                height: 30,
                left: 0,
                top: 1,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 15,
              }}
            />
            <div
              style={{
                width: 285,
                height: 32,
                left: 45,
                top: 0,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 6,
              }}
            />
          </div>
          <div style={{ width: 330, height: 32, left: 0, top: 41, position: 'absolute' }}>
            <div
              style={{
                width: 30,
                height: 30,
                left: 0,
                top: 1,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 15,
              }}
            />
            <div
              style={{
                width: 285,
                height: 32,
                left: 45,
                top: 0,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 6,
              }}
            />
          </div>
          <div style={{ width: 330, height: 32, left: 0, top: 0, position: 'absolute' }}>
            <div
              style={{
                width: 30,
                height: 30,
                left: 0,
                top: 1,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 15,
              }}
            />
            <div
              style={{
                width: 285,
                height: 32,
                left: 45,
                top: 0,
                position: 'absolute',
                background: 'linear-gradient(270deg, #F5F5F5 0%, #DFDFDF 100%)',
                borderRadius: 6,
              }}
            />
          </div>
        </StyledBagsRows>
      </ContentRow>
    </MainContainer >
  );
};
const Div = styled.div`
  display: flex;
  max-width: 152px;
  flex-direction: column;
  margin-top:5px;
`;
const StyledBagsRows = styled.div`
  position: relative;
  width: 330px;
   height: 242px;
`;

const Div2 = styled.div`
  border-radius: 8px;
  background: linear-gradient(270deg, #f5f5f5 18.75%, #dfdfdf 179.69%);
  display: flex;
  min-height: 13px;
  width: 100%;
  flex-direction: column;
`;

const Div3 = styled.div`
  border-radius: 8px;
  background: linear-gradient(270deg, #f5f5f5 18.75%, #dfdfdf 179.69%);
  display: flex;
  min-height: 13px;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
`;

const Div4 = styled.div`
  border-radius: 8px;
  background: linear-gradient(270deg, #f5f5f5 18.75%, #dfdfdf 179.69%);
  display: flex;
  min-height: 13px;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
`;

const MainContainer = styled.div`
  background-color: var(--Washmen-Background, #fafbfc);
  display: flex;
  max-width: 480px;
  width: 100%;
  padding-bottom: 44px;
  flex-direction: column;
  margin: 0 auto;
`;

const ContentRow = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  padding-left: 20px;
  flex-direction: column;
`;

const Column2Placeholder = styled.div`
  border-radius: 10px;
  background: linear-gradient(270deg, #f5f5f5 18.75%, #dfdfdf 179.69%);
  display: flex;
  min-height: 20px;
  max-width: 189px;
  flex-direction: column;
  margin-bottom: 29px;
`;

const PostContentContainer = styled.div`
  align-self: center;
  display: flex;
  margin-top: 36px;
  width: 100%;
  max-width: 260px;
  justify-content: space-between;
  gap: 20px;
  margin-left: 84px;
`;

const PostMediaContainer = styled.div`
  justify-content: flex-end;
  align-items: end;
  border-radius: 10px;
  background: linear-gradient(270deg, #f5f5f5 18.75%, #dfdfdf 179.69%);
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  padding: 50px 16px 4px 60px;
`;

const MediaPlaceholder = styled.div`
  aspect-ratio: 1.04;
  object-fit: contain;
  object-position: center;
  width: 25px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 150px;
  max-width: 100%;
`;

const PostTextPlaceholder = styled.div`
  justify-content: flex-end;
  align-items: center;
  border-radius: 16px;
  background: linear-gradient(270deg, #f5f5f5 18.75%, #dfdfdf 179.69%);
  align-self: center;
  display: flex;
  width: 70px;
  height: 130px;
  flex-direction: column;
  margin: auto 0;
`;

export default LoadingPlaceholderForCollection;
