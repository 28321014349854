import React from 'react';

const SliderComponent = () => {
  const sliderStyle: React.CSSProperties = {
    boxSizing: 'border-box',
    width: '72px',
    height: '6px',
    position: 'relative',
    marginTop: '25px',
    marginBottom: '30px',
  };

  const frame92881Style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '69px',
    position: 'absolute',
    left: 'calc(50% - 36px)',
    bottom: '0%',
    top: '0%',
    height: '100%',
  };

  const frame2609473Style: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexShrink: 0,
    position: 'relative',
  };

  const rectangleStyle: React.CSSProperties = {
    borderRadius: '100px',
    flexShrink: 0,
    width: '8px',
    height: '8px',
    position: 'relative',
  };


  const rectangle117Style: React.CSSProperties = {
    ...rectangleStyle,
    background: '#efeff4',
    opacity: 0.8,
  };

  return (
    <div style={sliderStyle}>
      <div style={frame92881Style}>
        <div style={frame2609473Style}>
          <div style={rectangle117Style}></div>
          <div style={rectangle117Style}></div>
          <div style={rectangle117Style}></div>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
