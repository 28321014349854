import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import WmAlert from 'components/alert';
import { FormattedMessage } from 'react-intl';
interface IErrorMessageProps {
  code: string | undefined;
  message: string | undefined;
}

const ErrorMessage: React.FC<IErrorMessageProps> = ({ message, code }) => {
  const reloadHanlder = () => window.location.reload();
  return (
    <Container bsPrefix="container-sm" className="mt-4">
      <Row>
        <Col xs={12}>
          <WmAlert type="danger" title={code === '404.7' ? `` : 'Something wrong has happened'}>
            <FormattedMessage id={message} defaultMessage="Unknown error" />
            <br />
            <span onClick={reloadHanlder} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              reload
            </span>
          </WmAlert>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorMessage;
