import React from 'react';
import classNames from 'classnames';
import Alert from 'react-bootstrap/Alert';
import { ReactComponent as DelayIcon } from 'assets/images/icons/delay.svg';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface Props {
  title?: string;
  fullWidth?: boolean;
}

const DelayAlert: React.FC<Props> = ({ title = '', fullWidth = false }) => (
  <Alert
    variant="warning"
    style={{ margin: fullWidth ? 'auto -1rem' : '' }}
    className={classNames('px-3', 'shadow-sm', 'border-0',)}
  >
    <Row noGutters>
      <Col xs={'auto'} className="mr-2 d-flex">
        <DelayIcon width="20px" height="17px" />
      </Col>
      <Col className={'align-self-center'}>
        <p style={{ fontFamily: 'Inter regular', fontSize: '0.8rem' }} className="text-warning-text m-0">
          {title}
        </p>
      </Col>
    </Row>
  </Alert>
);

export default DelayAlert;
