import React from 'react';
import { ReactComponent as TwoBags } from 'assets/images/icons/twoBags.svg';
import { ReactComponent as SingleBag } from 'assets/images/icons/singleBag.svg';
export const CareemPlusCampaign = 'CAREEM_PLUS_10_PERCENT_OFF';

export const COLORS: any = {
  // First Row
  black: { hex: '#000000', name: 'Black' },
  white: { hex: '#FFFFFF', name: 'White' },
  gray: { hex: '#7A7878', name: 'Gray' },
  silver: { hex: '#C0C0C0', name: 'Silver' },
  brown: { hex: '#795548', name: 'Brown' },
  beige: { hex: '#E1C699', name: 'Beige' },
  burgundy: { hex: '#800020', name: 'Burgundy' },
  red: { hex: '#E51C23', name: 'Red' },
  gold: { hex: '#CFB53B', name: 'Gold' },
  light_yellow: { hex: '#eee8aa', name: 'Light Yellow' },

  // Second Row
  navy: { hex: '#020265', name: 'Navy' },
  blue: { hex: '#03A9F4', name: 'Blue' },
  medium_blue: { hex: '#2246d8', name: 'Medium Blue' },
  cyan: { hex: '#00FFFF', name: 'Cyan' },
  light_blue: { hex: '#b0e0e6', name: 'Light Blue' },
  olive: { hex: '#5a7332', name: 'Olive' },
  green: { hex: '#3CB371', name: 'Green' },
  aqua: { hex: '#66cdbc', name: 'Aqua' },

  // Third Row
  light_green: { hex: '#98FB98', name: 'Light Green' },
  orange: { hex: '#FF5722', name: 'Orange' },
  salmon: { hex: '#ffa07a', name: 'Salmon' },
  yellow: { hex: '#FFE64B', name: 'Yellow' },
  pink: { hex: '#E91E63', name: 'Pink' },
  fuchsia: { hex: '#FF00FF', name: 'Fuchsia' },
  light_pink: { hex: '#f1b9bc', name: 'Light Pink' },
  purple: { hex: '#673AB7', name: 'Purple' },
  light_purple: { hex: '#ccb0e3', name: 'Light Purple' },
};

export const ITEM_PRIORITY: Record<string, number> = {
  ORIGINAL: 1,
  STAINED: 2,
  DAMAGED: 3,
};
const washAndFoldOptionsKey = [
  {
    key: 'ALWAYS_ASK_CUSTOMER',
    value: 'Always notify me of the items in question so I can decide (default)',
    selected: false,
  },
  {
    key: 'AUTOMATICALLY_SEND_TO_CP_AND_NOTIFY_CUSTOMER',
    value: 'Automatically transfer items to the clean & press service',
    selected: false,
  },
  {
    key: 'ALWAYS_SEND_TO_WF_AND_NOTIFY_CUSTOMER',
    value: 'Always wash any item I send in the wash & fold bag, regardless of the risk involved and notify me',
    selected: false,
  },
  {
    key: 'RETURN_AND_DO_NOT_WASH',
    value: 'Do not wash and return unprocessed',
    selected: false,
  },
];

export const STAIN_DAMAGE_WASH_FOLD_APPROVAL_KEYS = [
  {
    key: 'stainDamageApprovals',
    title: 'Stain and Damage Approvals',
    open: false,
    startIcon: <TwoBags />,
    isAutoApprove: false,
    description: 'By activating “Auto-approve” our laundry team will process all items with stains or damages without seeking your consent',
    skippedPreferences: true,
  },
  {
    key: 'washAndFoldApprovals',
    title: 'Wash and Fold Approvals',
    open: false,
    startIcon: <SingleBag />,
    options: [...washAndFoldOptionsKey],
    skippedPreferences: true,
    description:
      'In order to protect your delicate & expensive items, our team will flag items that we believe might not be suitable to Wash & Fold and will require your approval on how to proceed',
  },
];
