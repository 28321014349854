import styled from 'styled-components';

export const BgImageWithChildContainer = styled.div`
  width: 48.5%;
  height: 174px;
  flex-shrink: 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 8px;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const Text = styled.p`
  bottom: -8px;
  left: 8px;
  font-size: 12px;
  position: absolute;
  display: flex;
  font-family: 'Inter Medium';
  justify-content: space-between;
`;
export const TextContainer = styled.div`
  position: absolute;
  color: white;
  bottom: 0px;
  height: 40%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 14.5%, rgba(23, 23, 23, 0) 100%);
`;

export const TextContainerTop = styled.div`
  position: absolute;
  color: white;
  top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  height: 30%;
  width: 100%;
`;

export const BannerSuccess = styled.div`
  background: #d8fff8;
  border-radius: 20px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  position: relative;
`;

export const BannerError = styled.div`
  background: #fbeef2;
  border-radius: 20px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 20px;
  position: relative;
`;

export const BannerTextSuccess = styled.div`
  background: #d8fff8;
  color: #2ea692;
  text-align: center;
  font: 400 10px 'Inter-Regular', sans-serif;
  position: relative;
`;

export const BannerTextError = styled.div`
  color: #c83c3e;
  text-align: center;
  font: 400 10px 'Inter-Regular', sans-serif;
  position: relative;
`;
