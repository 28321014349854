import React from 'react';
import './style.scss';
import { ReactComponent as TickIcon } from 'assets/images/icons/ic-success.svg';

const Toast: React.FC<IToastProps> = ({ text }) => {
  return (
    <div className="toast-container">
      <div className="toast-message-container">
        <TickIcon />
        <>{text || 'You are all done!'}</>
      </div>
    </div>
  );
};

export default Toast;
