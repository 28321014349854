import React, { useMemo } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import CSS from 'csstype';

interface Props {
  show: boolean;
  fullScreen?: boolean;
}

const Loader: React.FC<Props> = ({ show, fullScreen = true }) => {
  const renderLoader = () => {
    if (!show) {
      return null;
    }
    return (
      <div style={fullScreen ? styles : undefined}>
        <Spinner size="sm" animation="border" role="status" variant="primary">
          {fullScreen && <span className="sr-only">Loading...</span>}
        </Spinner>
      </div>
    );
  };

  return renderLoader();
};

export default Loader;

const styles: CSS.Properties = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(255,255,255,0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
};
