import Loader from 'components/loader';
import React from 'react';
import { Button } from 'react-bootstrap';

const styles = {
  marginTop: 10,
  borderRadius: 6,
  fontSize: 14,
  minHeight: 42,
  width: '100%',
};
const ActionButton: React.FC<IActionButtonProps> = ({ text, onPress, isLoading, className }) => {
  return (
    <Button className={className} onClick={onPress} style={styles}>
      {isLoading ? <Loader show fullScreen={false} /> : text}
    </Button>
  );
};

export default ActionButton;
