import React, { useMemo } from 'react';
import classNames from 'classnames';
import Alert from 'react-bootstrap/Alert';
import { ReactComponent as Info } from 'assets/images/icons/ic-info-blue.svg';
import { ReactComponent as Warning } from 'assets/images/icons/ic-info-orange.svg';
import { ReactComponent as Danger } from 'assets/images/icons/ic-danger.svg';
import { ReactComponent as Success } from 'assets/images/icons/ic-success.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface Props {
  title?: string;
  type?: 'warning' | 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'dark' | 'light' | undefined;
}
const WmAlert: React.FC<Props> = ({ title = '', type = 'warning', children }) => {
  const rightIcon = useMemo(() => {

    switch (type) {
      case 'success':
        return <Success />
      case 'danger':
        return <Danger />
      case 'info':
        return <Info />
      default:
        return <Warning />
    }
  }, [type])
  return <Alert className={classNames('shadow-sm b-2 p-2 pl-3 pr-3 d-flex border-0')} variant={type}>
    <Row noGutters>
      <Col xs="auto" className="mr-2 ">
        {rightIcon}
      </Col>
      <Col>
        <p style={{ fontFamily: 'Inter Semi Bold', fontSize: 14 }} className={classNames('mb-0 pb-0 pt-1', `text-${type}-text`)}>
          {title}
        </p>
        <p className={classNames('text-slate-gray font-small p-0 m-0 ml-0', { 'text-slate-gray': type === 'warning' })}>{children}</p>
      </Col>
    </Row>
  </Alert>
}

export default WmAlert;
