import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import bootstrap custom theme
// https://create-react-app.dev/docs/adding-bootstrap/#using-a-custom-theme
import './assets/theme/theme.scss';

import bugsnagClient from 'utils/lib/bugnsag';
import 'utils/polyfills/arrayFrom';

const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
