import colors from 'theme/colors/color';
import { Colors, ThemeColors } from 'theme/theme.types';

export const washmenColors: Colors & ThemeColors = {
  ...colors,
  primary: colors.blue,
  background: colors.blue,
  linearGradient: 'linear-gradient(105deg, #5B52B0 5.56%, #4A40AE 30.96%, #372C9D 54.33%, #261B80 79.73%, #251B7D 107.16%)',
  secondary: colors.beige,
};
