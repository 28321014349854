import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

interface Props {
  show: boolean;
  onHide: () => any;
  image: string;
}

const ImgDialog: React.FC<Props> = ({ show, onHide, image }) => {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered animation backdrop="static">
      <Modal.Body>
        <Image src={image} fluid />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImgDialog;
